import styled from "styled-components"

import {
  MButton,
  StyledCircularProgress,
  TMButtonProps,
} from "src/ui/Button/MButton"
import { SVGProps } from "src/utils/tsUtil"

type TMButtonSize = TMButtonProps["size"]

export function IconButton({
  variant = "primary",
  color = "default",
  size = "medium",
  disabled = false,
  loading = false,
  Icon,
  ...rest
}: {
  Icon: React.FC<SVGProps>
} & Omit<TMButtonProps, "fullWidth" | "shape">) {
  const _disabled = disabled || loading

  return (
    <MButton
      variant={variant}
      color={color}
      disabled={_disabled}
      size={size}
      shape="circle"
      {...rest}
    >
      {!loading ? (
        <IconWrapper $size={size}>{<Icon />}</IconWrapper>
      ) : (
        <StyledCircularProgress
          $variant={variant}
          $color={color}
          size={getIconSize({ $size: size })}
          $disabled={_disabled}
        />
      )}
    </MButton>
  )
}

const IconWrapper = styled.div<{ $size: TMButtonSize }>`
  width: ${({ $size }) => getIconSize({ $size })};
  height: ${({ $size }) => getIconSize({ $size })};

  svg {
    display: block;
  }
`

function getIconSize({ $size }: { $size: TMButtonSize }) {
  switch ($size) {
    case "small":
      return "1.125rem"
    case "medium":
    default: {
      return "1.25rem"
    }
  }
}
